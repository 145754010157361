<template>
  <v-container
    class="products-list-page"
    fluid>
    <v-row class="products-row">
      <v-col
        offset-md="3"
        md="6"
        sm="12">
        <v-row>
          <v-col cols="12">
            <h2>Add member spending manual</h2>
          </v-col>
          <v-col
            cols="10"
            class="pr-1">
            <vue-tel-input-vuetify
              v-model="tel"
              label="เบอร์โทรศัพท์"
              default-country="th"
              dense
              outlined
              @country-changed="countryChange($event)" />
          </v-col>
          <v-col
            cols="2"
            class="pl-0">
            <v-btn
              outlined
              min-height="40px"
              min-width="48px"
              class="find-btn"
              @click="findMember()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="member">
          <v-col
            cols="6"
            class="py-1">
            <h3>ID</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberId }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Tel</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberTel }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Name</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberName }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Type</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberType }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Spending Total</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberSpending }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>DOB</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberBirthDate }}</p>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <h3>Email</h3>
          </v-col>
          <v-col
            cols="6"
            class="py-1">
            <p>{{ memberEmail }}</p>
          </v-col>
          <v-col
            cols="6">
            <h3>ยอดที่ต้องการเพิ่ม</h3>
          </v-col>
          <v-col
            cols="6">
            <v-text-field
              v-model.number="spending.amount"
              type="tel"
              dense
              outlined
              hide-details
              @keypress="$keypressNumber($event)" />
          </v-col>
          <v-col
            cols="6">
            <h4>อ้างอิง (เลขบิล, อื่น ๆ ให้ลูกค้ารู้)</h4>
          </v-col>
          <v-col
            cols="6">
            <v-text-field
              v-model="spending.reference"
              dense
              outlined
              hide-details />
          </v-col>
          <v-col
            cols="6">
            <h3>Note (โน้ต)</h3>
          </v-col>
          <v-col
            cols="6">
            <v-text-field
              v-model="spending.note"
              dense
              outlined
              hide-details />
          </v-col>
          <v-col cols="12">
            <v-btn
              :disabled="addSpendingBtnDisable"
              block
              color="primary"
              @click="tryToAddSpending()">
              ยืนยัน
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
// import TelInput from '../components/TelInput.vue'

const MemberService = new MemberProvider()
export default {
  components: {
    // TelInput
  },
  data () {
    return {
      tel: null,
      telInfo: null,
      member: null,
      spending: {
        amount: null,
        note: null,
        reference: null,
        memberId: null
      }
    }
  },
  computed: {
    memberName () {
      const firstName = this.member?.firstName || ''
      const lastName = this.member?.lastName || ''

      return `${firstName} ${lastName}`
    },
    memberTel () {
      return this.member?.tel || ''
    },
    memberId () {
      return this.member?.id || ''
    },
    memberSpending () {
      return this.member?.spending?.current || 0
    },
    memberBirthDate () {
      return this.member?.birthDate || ''
    },
    memberEmail () {
      return this.member?.email || ''
    },
    memberType () {
      return this.member?.type || ''
    },
    addSpendingBtnDisable () {
      return !this.spending.note || !this.spending.amount || !this.spending.reference
    }
  },
  watch: {
    tel: {
      handler (value) {
        if (value.length === 12 && this.telInfo.dialCode === '66') {
          this.tel = `+66${this.tel.slice(0)}`
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    async findMember () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING MEMBER INFO...'
        })

        const { data: member } = await MemberService.getMemberByTel(this.tel.replaceAll(' ', '').replaceAll('-', ''))
        this.member = member
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    tryToAddSpending () {
      this.setModal({
        value: true,
        title: 'Add Spending',
        message: 'Do you want to add spending?',
        confirmText: 'Confirm',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.addSpending()
      })
    },
    async addSpending () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'ADDING MEMBER SPENDING...'
        })

        this.spending.memberId = this.memberId
        const data = await MemberService.addMemberSpending(this.spending)

        if (data.message === 'done') {
          this.setSnackbar({
            value: true,
            message: 'เพิ่มยอดสำเร็จ',
            type: 'success'
          })
          this.spending = {
            amount: null,
            note: null,
            reference: null,
            memberId: null
          }
        } else {
          this.setSnackbar({
            value: true,
            message: 'เพิ่มยอดไม่สำเร็จ',
            type: 'error'
          })
        }
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[ADDING MEMBER SPENDING]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    countryChange (telInfo) {
      this.telInfo = telInfo
    },
    setTelInfo (tel, telInfo) {
      this.tel = this.setThTel(tel, telInfo)
      this.telInfo = telInfo
    },
    setThTel (tel, telInfo) {
      let result = tel
      if (telInfo.dialCode === '66' && tel.length === 10 && tel[0] === '0') {
        result = `+${telInfo.dialCode}${tel.slice(1)}`
      } else if (telInfo.dialCode === '66' && tel.length === 9 && tel[0] !== '0') {
        result = `+${telInfo.dialCode}${tel}`
      }

      return result
    }
  }
}
</script>
